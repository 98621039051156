import { Link } from 'gatsby'

import * as containerStyles from './Breadcrumbs.module.scss'

export type BreadcrumbsProps = {
  current: string
  currentPath: string
  secondLevel?: string
  secondLevelPath?: string
  thirdLevel?: string
  thirdLevelPath?: string
}

const Breadcrumbs = ({
  current,
  currentPath,
  secondLevel,
  secondLevelPath,
  thirdLevel,
  thirdLevelPath,
}: BreadcrumbsProps) => (
  <nav aria-label="breadcrumb">
    <ol className={containerStyles.breadcrumbs__list}>
      <li className={containerStyles.breadcrumbs__item}>
        <Link to="/">Home</Link>
      </li>

      {secondLevel && (
        <li className={containerStyles.breadcrumbs__item}>
          <Link to={`${secondLevelPath}`}>{secondLevel}</Link>
        </li>
      )}

      {thirdLevel && (
        <li className={containerStyles.breadcrumbs__item}>
          <Link to={`${thirdLevelPath}`}>{thirdLevel}</Link>
        </li>
      )}

      <li className={containerStyles.breadcrumbs__item}>
        <Link
          to={`${currentPath}`}
          aria-current="location"
          className={containerStyles.breadcrumbs__disabled}
        >
          {current}
        </Link>
      </li>
    </ol>
  </nav>
)

export default Breadcrumbs
