// extracted by mini-css-extract-plugin
export var aiChat__preview = "bw_lm";
export var aiChat__previewButton = "bw_lw";
export var aiChat__previewButtonWrapper = "bw_lv";
export var aiChat__previewContainer = "bw_ln";
export var aiChat__previewDescr = "bw_lt";
export var aiChat__previewFixedBottom = "bw_lx";
export var aiChat__previewLogo = "bw_lr";
export var aiChat__previewLogoWrapper = "bw_lp";
export var aiChat__previewTitle = "bw_ls";
export var pulseLightRed = "bw_lq";